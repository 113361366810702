// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

let baseUrl = "https://api.eurogirne.xyz/"; // 'http://10.0.0.176/' //'https://api.hoomy.xyz/'

export const environment = {
  production: false,
  baseUrl: `${baseUrl}`,
  apiUrl: `${baseUrl}api/v1/`,
  reportUrl: "https://report.eurogirne.xyz",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
