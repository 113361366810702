import { Injectable } from '@angular/core';
import {getBrowserLang, TranslocoService} from '@ngneat/transloco';
import { CookieService } from 'ngx-cookie-service';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  public languages: string[] = ['en', 'es', 'de', 'it', 'ru'];

  constructor(public translate: TranslocoService, private cookieService: CookieService) {
    let browserLang;
    // this.translate.addLangs(this.languages);
    if (this.cookieService.check('lang')) {
      browserLang = this.cookieService.get('lang');
    }
    else {
      this.setLanguage('en');
      browserLang = getBrowserLang();
    }
    translate.setActiveLang(browserLang.match(/en|tr|es|de|it|ru/) ? browserLang : 'en');
  }

  public setLanguage(lang) {
    this.translate.setActiveLang(lang);
    this.cookieService.set('lang', lang);
  }

}
