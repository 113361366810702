<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box" >


        <!-- <a routerLink="/" class="logo logo-light"> -->
        <!-- <span class="logo-lg"> -->
        <img src="assets/images/logo.jpg"  />
        <!-- </span> -->
        <!-- </a> -->
      </div>

      <button
        type="button"
        class="btn btn-sm px-3 font-size-16 header-item"
        id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)"
      >
        <i class="fa fa-fw fa-bars"></i>
      </button>

      <!-- App Search-->
      <form class="app-search d-none d-xl-block">
        <div class="position-relative">
          <input
            type="text"
            class="form-control"
            placeholder="{{ 'HEADER.SEARCH' | transloco }}"
          />
          <span class="bx bx-search-alt"></span>
        </div>
      </form>
    </div>

    <div class="d-flex">
      <div class="dropdown d-inline-block d-lg-none ms-2" ngbDropdown>
        <button
          type="button"
          class="btn header-item noti-icon"
          id="page-header-search-dropdown"
          data-toggle="dropdown"
          aria-haspopup="true"
          ngbDropdownToggle
          aria-expanded="false"
        >
          <i class="mdi mdi-magnify"></i>
        </button>
        <div
          class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
          ngbDropdownMenu
          aria-labelledby="page-header-search-dropdown"
        >
          <form class="p-3">
            <div class="m-0">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search ..."
                  aria-label="Recipient's username"
                />
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit">
                    <i class="mdi mdi-magnify"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button
          type="button"
          class="btn header-item"
          id="page-header-user-dropdown"
          ngbDropdownToggle
        >
          <img
            *ngIf="flagvalue !== undefined"
            src="{{ flagvalue }}"
            alt="Header Language"
            height="16"
          />
          <span class="ms-1">{{ countryName }}</span>
          <img
            *ngIf="flagvalue === undefined"
            src="{{ valueset }}"
            alt="Header Language"
            height="16"
          />
          <span *ngIf="flagvalue === undefined" class="ms-1">English</span>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <a
            href="javascript:void(0);"
            class="dropdown-item notify-item"
            *ngFor="let item of listLang"
            (click)="setLanguage(item.text, item.lang, item.flag)"
            [ngClass]="{ active: cookieValue === item.lang }"
          >
            <img
              src="{{ item.flag }}"
              alt="user-image"
              class="me-1"
              height="12"
            />
            <span class="align-middle">{{ item.text }}</span>
          </a>
          <!-- item-->
        </div>
      </div>

      <div class="dropdown d-none d-lg-inline-block ms-1">
        <button
          type="button"
          class="btn header-item noti-icon"
          data-toggle="fullscreen"
          (click)="fullscreen()"
        >
          <i class="bx bx-fullscreen"></i>
        </button>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button
          type="button"
          class="btn header-item"
          ngbDropdownToggle
          id="page-header-user-dropdown"
        >
          <img
            class="rounded-circle header-profile-user"
            src="assets/images/users/admin.png"
            alt="Header Avatar"
          />
          <span class="d-none d-xl-inline-block ms-1">{{
            authService.currentUser.name
          }}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <a class="dropdown-item d-block" href="javascript: void(0);"
            ><i class="bx bx-wrench font-size-16 align-middle me-1"></i
            >{{ "HEADER.LOGIN.SETTINGS" | transloco }}
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" (click)="logout()"
            ><i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"
            ></i>
            {{ "HEADER.LOGIN.LOGOUT" | transloco }}</a
          >
        </div>
      </div>

      <div class="dropdown d-inline-block">
        <button
          type="button"
          class="btn header-item noti-icon right-bar-toggle"
          (click)="toggleRightSidebar()"
        >
          <i class="bx bx-cog bx-spin"></i>
        </button>
      </div>
    </div>
  </div>
</header>
