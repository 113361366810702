<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a routerLink="/" class="logo logo-dark">
          <span class="logo-lg">
            <img src="assets/images/logo.jpg" alt="" height="17" />
          </span>
        </a>

        <a routerLink="/" class="logo logo-light">
          <span class="logo-lg">
            <img src="assets/images/logo.jpg" alt="" height="17" />
          </span>
        </a>
      </div>

      <button
        type="button"
        class="btn btn-sm px-3 font-size-16 d-lg-none header-item"
        (click)="toggleMenubar()"
      >
        <i class="fa fa-fw fa-bars"></i>
      </button>

      <!-- App Search-->
      <form class="app-search d-none d-xl-block">
        <div class="position-relative">
          <input
            type="text"
            class="form-control"
            placeholder="{{ 'search' | transloco }}"
          />
          <span class="bx bx-search-alt"></span>
        </div>
      </form>

      <!-- MEGAMENU  -->

      <div class="dropdown dropdown-mega d-none d-lg-block me-2" ngbDropdown>
        <button type="button" class="btn header-item" >
          {{ "megaMenu" | transloco }}
          <i class="mdi mdi-chevron-down"></i>
        </button>
        <div class="dropdown-menu dropdown-megamenu" ngbDropdownMenu>
          <div class="row">
            <div class="col-sm-10">
              <div class="row">
                <div class="col-md-2">
                  <h5 class="font-size-14 mt-0">
                    {{ "liveData" | transloco }}
                  </h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a
                        class="menu-item-hv"
                        [routerLink]="['/live-data/sessions']"
                        >{{ "sessions" | transloco }}</a
                      >
                    </li>
                    <li>
                      <a
                        class="menu-item-hv"
                        [routerLink]="['/live-data/close-day']"
                        >{{ "closeDay" | transloco }}</a
                      >
                    </li>
                  </ul>
                </div>

                <div class="col-md-2">
                  <h5 class="font-size-14 mt-0">
                    {{ "Chip" | transloco }}
                  </h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a
                        class="menu-item-hv"
                        [routerLink]="['/chip-transfer']"
                        >{{ "chipTransfer" | transloco }}</a
                      >
                    </li>

                    <li>
                      <a class="menu-item-hv" [routerLink]="['/chip-type']">{{
                        "chipType" | transloco
                      }}</a>
                    </li>
                  </ul>
                </div>

                <div class="col-md-2">
                  <h5 class="font-size-14 mt-0">
                    {{ "machine" | transloco }}
                  </h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a class="menu-item-hv" [routerLink]="['/meters']">{{
                        "meters" | transloco
                      }}</a>
                    </li>

                    <li>
                      <a class="menu-item-hv" [routerLink]="['/machine']">{{
                        "machines" | transloco
                      }}</a>
                    </li>
                  </ul>
                </div>

                <div class="col-md-2">
                  <h5 class="font-size-14 mt-0">
                    {{ "users" | transloco }}
                  </h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a class="menu-item-hv" [routerLink]="['/customer']">{{
                        "customers" | transloco
                      }}</a>
                    </li>
                    <li>
                      <a class="menu-item-hv" [routerLink]="['/staff']">{{
                        "staff" | transloco
                      }}</a>
                    </li>
                  </ul>
                </div>

                <div class="col-md-2">
                  <h5 class="font-size-14 mt-0">
                    {{ "live" | transloco }}
                  </h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a class="menu-item-hv" [routerLink]="['/live-table']">{{
                        "liveTable" | transloco
                      }}</a>
                    </li>

                    <li>
                      <a class="menu-item-hv" [routerLink]="['/open-day']">{{
                        "openDay" | transloco
                      }}</a>
                    </li>
                  </ul>
                </div>

                <div class="col-md-2">
                  <h5 class="font-size-14 mt-0">
                    {{ "others" | transloco }}
                  </h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a class="menu-item-hv" [routerLink]="['/hospitality']">{{
                        "hospitality" | transloco
                      }}</a>
                    </li>

                    <li>
                      <a class="menu-item-hv" [routerLink]="['/currency']">{{
                        "currency" | transloco
                      }}</a>
                    </li>

                    <li>
                      <a class="menu-item-hv" [routerLink]="['/logs']">{{
                        "logs" | transloco
                      }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="col-sm-2">
              <div>
                <img
                  src="assets/images/megamenu-img.png"
                  alt=""
                  style="height: 120px"
                  class="img-fluid mx-auto d-block"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex">
      <div class="dropdown d-inline-block d-lg-none me-2" ngbDropdown>
        <button
          type="button"
          class="btn header-item noti-icon"
          id="page-header-search-dropdown"
          ngbDropdownToggle
        >
          <i class="mdi mdi-magnify"></i>
        </button>
        <div
          class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
          ngbDropdownMenu
        >
          <form class="p-3">
            <div class="m-0">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search ..."
                  aria-label="Recipient's username"
                />
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit">
                    <i class="mdi mdi-magnify"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button
          type="button"
          class="btn header-item"
          id="page-header-user-dropdown"
          ngbDropdownToggle
        >
          <img
            *ngIf="flagvalue !== undefined"
            src="{{ flagvalue }}"
            alt="Header Language"
            height="16"
          />
          <span class="ms-1">{{ countryName }}</span>
          <img
            *ngIf="flagvalue === undefined"
            src="{{ valueset }}"
            alt="Header Language"
            height="16"
          />
          <span *ngIf="flagvalue === undefined" class="ms-1">English</span>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <a
            href="javascript:void(0);"
            class="dropdown-item notify-item"
            *ngFor="let item of listLang"
            (click)="setLanguage(item.text, item.lang, item.flag)"
            [ngClass]="{ active: cookieValue === item.lang }"
          >
            <img
              src="{{ item.flag }}"
              alt="user-image"
              class="ms-1"
              height="12"
            />
            <span class="align-middle"> &nbsp;{{ item.text }}</span>
          </a>
          <!-- item-->
        </div>
      </div>

      <div class="dropdown d-none d-lg-inline-block me-1">
        <button
          type="button"
          class="btn header-item noti-icon"
          data-toggle="fullscreen"
          (click)="fullscreen()"
        >
          <i class="bx bx-fullscreen"></i>
        </button>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button
          type="button"
          class="btn header-item"
          id="page-header-user-dropdown"
          ngbDropdownToggle
        >
          <img
            class="rounded-circle header-profile-user"
            src="assets/images/users/admin.png"
            alt="Header Avatar"
          />
          <span class="d-none d-xl-inline-block ms-1">{{
            authService.currentUser.name
          }}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <a class="dropdown-item d-block" href="javascript: void(0);"
            ><i class="bx bx-wrench font-size-16 align-middle me-1"></i
            >{{ "Settings" | transloco }}
          </a>
          <!-- <a class="dropdown-item" href="javascript: void(0);"
            ><i class="bx bx-lock-open font-size-16 align-middle me-1"></i>
            {{ "HEADER.LOGIN.LOCK_SCREEN" | transloco }}</a
          > -->
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" (click)="logout()"
            ><i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"
            ></i>
            {{ "logOut" | transloco }}</a
          >
        </div>
      </div>

      <div class="dropdown d-inline-block">
        <button
          type="button"
          class="btn header-item noti-icon right-bar-toggle"
          (click)="onSettingsButtonClicked()"
        >
          <i class="bx bx-cog bx-spin"></i>
        </button>
      </div>
    </div>
  </div>
</header>
<div class="topnav">
  <div
    class="container-fluid"
    style="
      overflow-x: auto;
      overflow-y: hidden;
      margin-left: 0;
      margin-right: 0;
      max-width: 100%;
      width: 100%;
    "
  >
    <nav
      class="navbar navbar-light navbar-expand-lg topnav-menu active"
      style="position: unset"
    >
      <div class="collapse navbar-collapse active" id="topnav-menu-content">
        <ul class="navbar-nav">
          <ng-container *ngFor="let item of menuItems">
            <li class="nav-item dropdown" style="position: unset">
              <a
                class="nav-link dropdown-toggle arrow-none"
                *ngIf="!item.subItems"
                href="javascript: void(0);"
                id="topnav-components"
                [routerLink]="item.link"
                role="button"
              >
                <i class="bx {{ item.icon }} me-2"></i
                >{{ item.label | transloco }}
                <div class="arrow-down" *ngIf="hasItems(item)"></div>
              </a>

              <a
                *ngIf="item.subItems"
                class="nav-link dropdown-toggle arrow-none"
                (click)="onMenuClick($event)"
                href="javascript: void(0);"
                id="topnav-components"
                role="button"
              >
                <i class="bx {{ item.icon }} me-1"></i>
                {{ item.label | transloco }}
                <div class="arrow-down"></div>
              </a>

              <div
                class="dropdown-menu"
                [ngClass]="{
                  'dropdown-mega-menu-xl px-2': item.subItems.length > 11
                }"
                aria-labelledby="topnav-dashboard"
                *ngIf="hasItems(item)"
              >
                <ng-template
                  ngFor
                  let-i="index"
                  let-subitem
                  [ngForOf]="item.subItems"
                >
                  <a
                    *ngIf="item.subItems.length < 11 && !hasItems(subitem)"
                    class="col dropdown-item side-nav-link-ref"
                    [routerLink]="subitem.link"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                  >
                    {{ subitem.label | transloco }}
                  </a>
                  <div *ngIf="item.subItems.length > 11">
                    <div *ngIf="i % 3 == 0" class="row">
                      <div class="col-lg-4">
                        <a
                          class="dropdown-item side-nav-link-ref"
                          routerLinkActive="active"
                          [routerLink]="subitem.link"
                          >{{ item.subItems[i].label | transloco }}</a
                        >
                      </div>
                      <div class="col-lg-4">
                        <a
                          class="dropdown-item side-nav-link-ref"
                          routerLinkActive="active"
                          [routerLink]="item.subItems[i + 1]?.link"
                          >{{ item.subItems[i + 1]?.label | transloco }}</a
                        >
                      </div>
                      <div class="col-lg-4">
                        <a
                          class="dropdown-item side-nav-link-ref"
                          routerLinkActive="active"
                          [routerLink]="item.subItems[i + 2]?.link"
                          >{{ item.subItems[i + 2]?.label | transloco }}</a
                        >
                      </div>
                    </div>
                  </div>

                  <div class="dropdown" *ngIf="hasItems(subitem)">
                    <a
                      class="dropdown-item dropdown-toggle"
                      href="javascript: void(0);"
                      (click)="onMenuClick($event)"
                      >{{ subitem.label | transloco }}
                      <div class="arrow-down"></div>
                    </a>
                    <div class="dropdown-menu">
                      <ng-template
                        ngFor
                        let-subSubitem
                        [ngForOf]="subitem.subItems"
                      >
                        <a
                          class="dropdown-item side-nav-link-ref"
                          *ngIf="!hasItems(subSubitem)"
                          href="javascript: void(0);"
                          [routerLink]="subSubitem.link"
                          routerLinkActive="active"
                          >{{ subSubitem.label | transloco }}</a
                        >
                        <div class="dropdown" *ngIf="hasItems(subSubitem)">
                          <a
                            class="dropdown-item dropdown-toggle"
                            (click)="onMenuClick($event)"
                            >{{ subSubitem.label | transloco }}
                            <div class="arrow-down"></div>
                          </a>
                          <div class="dropdown-menu">
                            <ng-template
                              ngFor
                              let-subSubSubitem
                              [ngForOf]="subSubitem.subItems"
                            >
                              <a
                                class="dropdown-item side-nav-link-ref"
                                href="javascript: void(0);"
                                [routerLink]="subSubSubitem.link"
                                routerLinkActive="active"
                                >{{ subSubSubitem.label | transloco }}</a
                              >
                            </ng-template>
                          </div>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </ng-template>
              </div>
            </li>
          </ng-container>
        </ul>
      </div>
    </nav>
  </div>
</div>
