<div class="account-pages my-5 pt-sm-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="bg-soft bg-primary">
            <div class="row">
              <div class="col-7">
                <div class="text-primary p-4">
                  <h5 class="text-primary">{{'welcomeBack' | transloco}}</h5>
                  <p >{{'signInToContinue' | transloco}}</p>
                </div>
              </div>
              <div class="col-5 align-self-end">
                <img
                  src="assets/images/profile-img.png"
                  alt=""
                  class="img-fluid"
                />
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div>
              <a>
                <div
                  style="margin-top: 4px"
                  class="avatar-md profile-user-wid mb-3"
                >
                  <span class="avatar-title rounded-circle bg-light">
                    <img
                      src="assets/images/logo.jpg"
                      alt=""
                      style="border-radius: 2px"
                      height="34"
                    />
                  </span>
                </div>
              </a>
            </div>
            <div class="p-2">
              <form
                class="form-horizontal"
                [formGroup]="loginForm"
                (ngSubmit)="onSubmit()"
              >
                <div class="mb-3">
                  <label for="email" >{{'username' | transloco}}</label>
                  <input
                    type="text"
                    formControlName="username"
                    class="form-control"
                    id="email"
                    placeholder="Username"
                    [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
                  />
                  <div
                    *ngIf="submitted && f.username.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="f.username.errors.required" >
                     {{'userNameRequired' |transloco}}
                    </div>
                  </div>
                </div>

                <div class="mb-3">
                  <label for="password" >{{'password' |transloco}}</label>
                  <div class="input-group auth-pass-inputgroup">
                    <input
                      [type]="passwordVisible ? 'text' : 'password'"
                      class="form-control"
                      id="password"
                      formControlName="password"
                      placeholder="Enter password"
                      [ngClass]="{
                        'is-invalid': submitted && f.password.errors
                      }"
                      aria-label="Password"
                      aria-describedby="password-addon"
                    />

                    <button
                      (click)="seePassword()"
                      class="btn btn-light ms-0"
                      type="button"
                      id="password-addon"
                    >
                      <i class="mdi mdi-eye-outline"></i>
                    </button>
                    <div
                      *ngIf="submitted && f.password.errors"
                      class="invalid-feedback"
                    >
                      <span *ngIf="f.password.errors.required"
                        >{{'passwordRequired' | transloco }}</span
                      >
                    </div>
                  </div>
                </div>

                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="remember-check"
                  />
                  <label class="form-check-label" for="remember-check" >
                   {{'rememberMe' | transloco}}
                  </label>
                </div>

                <div class="mt-3 d-grid">
                  <button
                    class="btn btn-primary"
                    type="submit"
                    [disabled]="submitted"
                  >
                    <i
                      *ngIf="submitted"
                      class="bx bx-loader bx-spin font-size-16 align-middle me-2"
                    ></i
                    >{{'logIn' | transloco}}
                  </button>
                </div>
                <!-- <div class="mt-4 text-center">
                  <a routerLink="/account/reset-password" class="text-muted"
                    ><i class="mdi mdi-lock mr-1"></i> Forgot your password?</a
                  >
                </div> -->
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- end container -->
</div>
<!-- end page -->
