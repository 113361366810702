import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "../shared/services/authentication.service";
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  submitted = false;
  error = "";
  returnUrl: string;
  passwordVisible: boolean = false;

  // set the currenr year
  year: number = new Date().getFullYear();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService,
   private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ["", [Validators.required]],
      password: ["", [Validators.required]],
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  /**
   * Form submit
   */
  onSubmit() {
    this.submitted = true;
    const obj = this.loginForm.getRawValue();
    this.authService.login(obj).subscribe((resp) => {
      this.submitted = false;
      if(resp){
        this.router.navigate(["/"]);
      }else{
        this.toastr.error('An error occurred')

      }
    }, error => {
      console.log(error);
      this.submitted = false;
      this.toastr.error(error ?? 'An error occurred')
    });
  }

  seePassword() {
    this.passwordVisible = !this.passwordVisible;
  }
}
